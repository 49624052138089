<template>
  <div class="email-rightbar mb-3">
    <div class="card">
      <div class="card-body">
        <h4 class="mb-4">Conversas</h4>

        <b-alert :show="!conversations.items.length" variant="warning">
          Nenhuma conversa
        </b-alert>

        <ul class="message-list">
          <li v-for="(conversation, index) in conversations.items" :key="index">
            <router-link
              :to="{
                name: 'viewConversation',
                params: { id: conversation.id },
              }"
            >
              <div class="col-mail col-mail-0">
                <a class="title mx-3">
                  {{
                    (conversation.sender_id === currentUser.id
                      ? conversation.receiver
                      : conversation.sender) | name
                  }}</a
                >
              </div>
              <div class="col-mail col-mail-2">
                <a class="subject">
                  <i v-if="conversation.closed" class="mdi mdi-check-bold"></i>
                  {{ conversation.subject }}
                </a>
                <div class="date">
                  {{ conversation.updated_at | moment("D MMM YYYY") }}
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-7">
        Exibindo {{ (conversations.page - 1) * conversations.perPage }} -
        {{ conversations.page * conversations.perPage }} de
        {{ +conversations.total }}
      </div>
      <div class="col-5">
        <div class="btn-group float-right">
          <b-pagination
            v-model="conversations.page"
            :total-rows="conversations.total"
            :per-page="conversations.perPage"
            @input="loadConversations"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { mapState } from "vuex";

export default {
  data() {
    return {
      conversations: {
        items: [],
        page: 1,
        totalRows: 0,
        perPage: 20,
        total: 0,
      },
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
  },

  created() {
    this.loadConversations();
  },

  methods: {
    loadConversations() {
      const closed = {
        open: false,
        closed: true,
      }[this.$route.query.filter];

      api
        .getConversations({
          closed,
          page: this.conversations.page,
        })
        .then((conversations) => {
          this.conversations = conversations;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar conversas!", "error");
        });
    },

    onPageChange() {
      this.conversations = this.conversations.slice();
    },
  },

  watch: {
    "$route.query.filter"() {
      this.loadConversations();
    },
  },
};
</script>
